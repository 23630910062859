import React from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import toastr from 'toastr'
import Sidebar from '../Components/global/Sidebar'
import Topbar from '../Components/global/Topbar'


const MainLayout = (props) => {
  const [openSidebarToggle, setOpenSidebarToggle] = React.useState(false)

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle)
  }
  return <div className='withOut_childsideBar_grid_container'>
    <Topbar OpenSidebar={OpenSidebar} {...props} />
    <Sidebar
      openSidebarToggle={openSidebarToggle}
      OpenSidebar={OpenSidebar}
      {...props} >
    </Sidebar>
    {/* <ChildSideBarEarnCoins openSidebarToggle={openSidebarToggle}
      OpenSidebar={OpenSidebar}
      {...props}>
    </ChildSideBarEarnCoins> */}
    {/* <MembershipChackoutHome {...props} /> */}
    <Outlet />
  </div>
}

//
export const ProtectedRoutes = (props) => {
  const Navigate = useNavigate()
  const { pathname } = useLocation()
  const auth = localStorage.getItem('loggedin');
  // check Channel Name
  if (pathname !== "/account-information") {
    if (localStorage.getItem("channelName") !== undefined) {
      if (localStorage.getItem("channelName") === "null") {
        Navigate("account-information", { required: true })
        toastr.info("Please Enter Youtube Channel For Explore Site")
      }
    }
  }


  return auth ? <Outlet /> : <Navigate to={"/"} />;
}
