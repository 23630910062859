
import React from 'react'
import { AiOutlineDashboard } from "react-icons/ai";
import { HiOutlineMegaphone } from "react-icons/hi2";
import { FaCoins } from "react-icons/fa";
import { IoStorefrontOutline } from "react-icons/io5";
import { IoStatsChartOutline } from "react-icons/io5";
import { MdConnectWithoutContact } from "react-icons/md";
import { MdConnectWithoutExtention } from "react-icons/md";
import { FaSpinner as FaSpinnerIcon } from 'react-icons/fa';
import { IoIosContacts } from "react-icons/io";
import './TopSideBar.css'
import "../Common/chat.js";
const Sidebar = (props) => {
  return (
    <aside id="sidebar" className={props.openSidebarToggle ? "sidebar-responsive" : ""}>
      <div className='flex-space-between'>
        {/* <div className='sidebar-brand'>
          <BsCart3 className='icon_header' /> SHOP
        </div> */}
        <span className='icon close_icon' onClick={props.OpenSidebar}>X</span>
      </div>

      <ul className='sidebar-list'>
        <li className={
          props.location.pathname === "/dashboard"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/dashboard')
          props.OpenSidebar()
        }}>
          <a>
            <AiOutlineDashboard className={
              props.location.pathname === "/dashboard"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              props.location.pathname === "/dashboard"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }>
              Dashboard
            </div>
          </a>
        </li>
        <li className={
          props.location.pathname === "/my-capmaign"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/my-capmaign')
          props.OpenSidebar()

        }}>
          <a >
            <HiOutlineMegaphone className={
              props.location.pathname === "/my-capmaign"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              props.location.pathname === "/my-capmaign"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }> Campaign</div>
          </a>
        </li>
        <li className={
          (props.location.pathname === "/earn-coins")
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/earn-coins')
          props.OpenSidebar()
        }}>
          <a >
            <FaCoins className={
              (props.location.pathname === "/earn-coins")
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              (props.location.pathname === "/earn-coins")
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }>Earn Coins</div>
          </a>
        </li>
        <li className={
          props.location.pathname === "/buy-worker"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/buy-worker')
          props.OpenSidebar()
        }}>
          <a>
            <IoStorefrontOutline className={
              props.location.pathname === "/buy-worker"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              props.location.pathname === "/buy-worker"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }>Shop</div>
          </a>
        </li>
        <li className={
          props.location.pathname === "/analytical-report"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/analytical-report')
          props.OpenSidebar()
        }}>
          <a>
            <IoStatsChartOutline className={
              props.location.pathname === "/analytical-report"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'} />
            <div className={
              props.location.pathname === "/analytical-report"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }> Activity</div>
          </a>
        </li>
        <li className={
          props.location.pathname === "/affiliates"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/affiliates')
          props.OpenSidebar()
        }}>
          <a>
          <MdConnectWithoutContact  className={
              props.location.pathname === "/affiliates"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              props.location.pathname === "/affiliates"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }> Affiliates</div>
          </a>
        </li>
        <li className={
          props.location.pathname === "/extension"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/extension')
          props.OpenSidebar()
        }}>
          <a>
          <FaSpinnerIcon  className={
              props.location.pathname === "/extension"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              props.location.pathname === "/extension"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }> Extension</div>
          </a>
        </li>
        <li className={
          props.location.pathname === "/ticket"
            ? 'sidebar-red-list-item'
            : "sidebar-list-item"
        } onClick={() => {
          props.navigate('/support')
        }}>
          <a>
          <IoIosContacts className={
              props.location.pathname === "/support"
                ? 'sidebar_red_icon'
                : 'sidebar_white_icon'
            } />
            <div className={
              props.location.pathname === "/support"
                ? "sidebar_red_text"
                : "sidebar_white_text"
            }> Support</div>
          </a>
        </li>
      </ul>
    </aside>
  );

};

export default Sidebar;
