window.Tawk_API = window.Tawk_API || {};

window.Tawk_API.onLoad = function () {
  // console.log("Tawk.to est chargé");
};

window.Tawk_API.onChatMaximized = function () {
  // console.log("Chat ouvert (secondFrame passe à 'open')");
  // alert("secondFrame est maintenant ouvert !");
};

window.Tawk_API.onChatMinimized = function () {
  // console.log("Chat fermé (secondFrame passe à 'close')");
  // alert("secondFrame est maintenant fermé !");
};
(function () {
  var s1 = document.createElement("script"),
    s0 = document.getElementsByTagName("script")[0];
  s1.async = true;
  s1.src = "https://embed.tawk.to/6627946ba0c6737bd12f534b/1hs59ni0g";
  s1.charset = "UTF-8";
  // console.log(JSON.stringify(s1));
  s1.setAttribute("crossorigin", "*");
  s0.parentNode.insertBefore(s1, s0);
})();

window.addEventListener("load", function () {
  let fourthFrame = "";
  let secondFrame = "";
  setTimeout(function () {
    var chatsWidget = document.getElementsByClassName("widget-visible");
    for (let element of chatsWidget) {
      const idElement = element.getAttribute("id");
      const iframes = element.getElementsByTagName("iframe");
      const elementById = document.getElementById(idElement);
      for (let j = 0; j < iframes.length; j++) {
        const iframe = iframes[j];
        // console.log(iframe);
        iframe.style = null;
        if (j === 0) {
          iframe.style.position = "fixed";
          iframe.style.bottom = "70px";
          iframe.style.right = "10px";
          iframe.style.zIndex = "1000001";
        } else if(j === 1) {
          secondFrame = iframe.id;
        } else if(j === 3) {
          fourthFrame = iframe.id;
          // console.log("forthFrame défini à :", fourthFrame);
          iframe.style.opacity = 0;
        }
      }
    }
  }, 2000); // Attendre que Tawk.to se charge avant de modifier la position
  // Fonction pour diminuer l'opacité
  const decreaseOpacity = () => {
    const fourthFrameElement = document.getElementById(fourthFrame);
    if (fourthFrameElement) {
      let currentOpacity = parseFloat(fourthFrameElement.style.opacity) || 1;
      currentOpacity = 0; // Diminuer de 0.1, minimum 0
      setTimeout(() => {
        fourthFrameElement.style = null;
        fourthFrameElement.style.display = "none";
        fourthFrameElement.style.opacity = currentOpacity;
        // console.log("Nouvelle opacité de fourthFrame :", currentOpacity);
        // console.log("Nouvelle opacité de fourthFrame :", fourthFrame);
      }, 1000)
      
    }
  };
  // Utiliser l'API Tawk.to pour détecter les changements d'état
  Tawk_API.onChatMaximized = function () {
    // console.log("Chat ouvert");
    decreaseOpacity(); // Diminuer l'opacité quand le chat s'ouvre
  };

  Tawk_API.onChatMinimized = function () {
    // console.log("Chat fermé");
    decreaseOpacity(); // Diminuer l'opacité quand le chat se ferme
  };

  Tawk_API.onLoad = function () {
    // console.log("Tawk.to chargé, écoute des événements activée");
  };
});
